import React, { useState, useRef } from "react";
import tw from "twin.macro";
import styled, { createGlobalStyle, keyframes } from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import LogoImage from "../../images/LOGO_L.png";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-300`}
  }
`;

const LogoImg = styled.a`
  position: absolute;
  bottom: 16px;  
  right: 16px;  
  z-index: 30;
  img {
    height: 64px;  
    width: auto;
  }
`;

const PrimaryLink = styled.a`
  ${tw`rounded-full bg-primary-500 text-gray-100 px-8 py-3 mt-10 text-sm sm:text-base font-bold shadow transition duration-300`}
  &:hover {
    ${tw`bg-primary-700 text-gray-200`}
  }
  position: absolute;
  top: 10px;
  right: 8%; /* This positions the button 20% from the right side */
  z-index: 30;
  cursor: pointer;
`;

const MuteButton = styled.button`
  ${tw`flex items-center justify-center rounded-full bg-primary-500 text-gray-100 p-2 text-sm sm:text-base font-bold shadow transition duration-300`}
  &:hover {
    ${tw`bg-primary-700 text-gray-200`}
  }
  position: absolute;
  bottom: 50px;
  left: 20px;
  z-index: 100; /* Make sure this is high enough */
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: currentColor;
    stroke-width: 2;
  }
`;

const Container = styled.div`
  ${tw`max-w-screen-xl w-full h-screen`}  
  margin: 0;
  padding: 0;
  overflow: hidden;  
`;

const BackgroundVideoWrapper = styled.div`
  ${tw`absolute top-0 left-0 w-full h-auto min-h-full`}  
`;

const BackgroundVideo = styled.video`
  ${tw`absolute bottom-0 left-0 w-full h-auto min-h-full`}  
  object-fit: cover;
  object-position: bottom;  
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = styled.a`z-20 absolute justify-right items-right`;
const Content = tw.div`max-w-screen-xl flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-300 leading-snug mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-25px);
  }
`;

const DownArrow = styled.div`
  ${tw`absolute bottom-0 left-1/2 transform -translate-x-[-90%] cursor-pointer`}
  font-size: 10rem;
  color: white;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
  z-index: 30;
`;

const scrollToContactSection = () => {
  const nextSection = document.getElementById("contact");
  if (nextSection) {
    nextSection.scrollIntoView({ behavior: "smooth" });
  }
};

export default () => {
  const navLinks = [
    <NavLinks key={2}>
      <PrimaryLink onClick={scrollToContactSection}>
        Contact us about our patented WhatsApp Smart Lockers
      </PrimaryLink>
    </NavLinks>
  ];

  const scrollToNextSection = () => {
    const nextSection = document.getElementById("NextGen");
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  // State for managing sound
  const [isMuted, setIsMuted] = useState(true);
  const videoRef = useRef(null);

  // Function to toggle sound
  const toggleSound = () => {
    const newMutedState = !isMuted;
    setIsMuted(newMutedState);
    if (videoRef.current) {
      videoRef.current.muted = newMutedState;
    }
  };

  return (
    <Container>
      <BackgroundVideoWrapper>
        <BackgroundVideo ref={videoRef} autoPlay loop muted={isMuted}>
          <source src="LOCKME_MASTER_v008.mp4" type="video/mp4" />
          Your browser does not support the video tag. This video showcases LockMe's patented WhatsApp Smart Locker system, demonstrating how users can manage lockers, make payments, and access lockers purely through a WhatsApp chat.
        </BackgroundVideo>
      </BackgroundVideoWrapper>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading>
            Manage Lockers with WhatsApp
            <span>LockMe WhatsApp Smart Lockers</span>
          </Heading>
        </Content>
      </HeroContainer>
      <LogoImg href="https://www.lock-me.com">
        <img src={LogoImage} alt="LockMe WhatsApp Smart Locker Logo" />
      </LogoImg>
      {/* Sound Toggle Button with Volume Icon */}
      <MuteButton onClick={toggleSound}>
        {isMuted ? (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polygon points="11 5 6 9 6 15 11 19 11 5"></polygon>
            <line x1="23" y1="9" x2="17" y2="15"></line>
            <line x1="17" y1="9" x2="23" y2="15"></line>
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <polygon points="11 5 6 9 6 15 11 19 11 5"></polygon>
            <path d="M19.07 4.93a10 10 0 0 1 0 14.14"></path>
            <path d="M15.54 8.46a5 5 0 0 1 0 7.07"></path>
          </svg>
        )}
      </MuteButton>
      <DownArrow
        aria-label="Scroll to see how LockMe's WhatsApp Smart Lockers work"
        style={{
          position: 'absolute',
          bottom: '0',
          left: '45%',
          transform: 'translateX(-50%)',
          zIndex: 30,
        }}
        onClick={scrollToNextSection}
      >
        <svg width="198" height="198" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 9L12 15L18 9" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </DownArrow>
    </Container>
  );
};
