import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import ProfileThreeColGridCards from "components/cards/ProfileThreeColGrid.js"
import DownloadApp from "components/cta/DownloadApp.js";
import ChatLockers from "components/hero/chatLockers.js";
import FAQ from "components/faqs/SingleCol.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/FiveColumnDark.js";
import ThreeColumnWithProfileImage from "components/testimonials/ThreeColumnWithProfileImage.js"
import TwoColSingleFeatureWithSats2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js"
import './index.css';
import Different from "components/features/ThreeColWithSideImage.js";

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default function App() {
  const [animation, setAnimation] = React.useState(true);

  const goTo = async (id) => {
    console.log(id);
    setAnimation(false);

    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }

    await sleep(500); // Wait for the scroll to complete

    setAnimation(true);
  };

  async function contact(email, name, message) {
    try {
        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({email, subject: `LockMe Contact from ${name}`, message}),
            headers: { 'Content-Type': 'application/json' },
            mode: 'no-cors', // no-cors, *cors, same-origin
        };

        fetch('https://europe-west1-mylock-541a9.cloudfunctions.net/contactWeb', requestOptions)
        .then(res => {
          console.log(res);
          return { code: 400, response: res };
        })
        .catch(err => {
          console.log(err);
          return { error: '<contact> error: ' + err, code: 401 };
        });
    }
    catch (e) {
        console.log("<contact> error: ", e);
        return { error: '<contact> error: ' + e, code: 401 };
    }
}

  return (
    <Router>
      <Switch>
        <Route path="/">
          <AnimationRevealPage disabled={!animation}>
            <Hero goTo={(id) => goTo(id)} />
            <TwoColSingleFeatureWithSats2/>
            <ChatLockers/>
            <Different />
            <ContactUsForm contact={contact} />
            <Footer />
          </AnimationRevealPage>
        </Route>
      </Switch>
    </Router>
  );
}