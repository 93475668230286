import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import HandleIconImage from "images/shop-icon.svg";
import ShopIconImage from "images/celebration-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-primary-300`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

const Different = ({ cards = null, heading = (<> <h2 tw="text-secondary-300">Unlock the future</h2>  </>), subheading = "", 
      description = "LockMe's software provides the best tool to interact with your smart lockers. A patented conversational software platform that allows users to directly chat with the system." }) => {
  /*
   * This component has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   */

  const defaultCards = [
    { imageSrc: ShieldIconImage, title: "High conversion", description: "Boost user engagement and conversion rates with the convenience of WhatsApp access." },
    { imageSrc: CustomizeIconImage, title: "Practicity", description: "Simplify your client's life with hassle-free locker solutions." },
    { imageSrc: SupportIconImage, title: "Automatization", description: "Streamline your operations with automated locker systems that rely on AI technology." },
    { imageSrc: FastIconImage, title: "Fast", description: "Instant access to your belongings with lightning-speed technology, even if the lockers experience network outage." },
    { imageSrc: ReliableIconImage, title: "Remote", description: "Manage your lockers from anywhere with ease thanks to our dedicated WhatsApp admin-flows." },
    { imageSrc: SimpleIconImage, title: "Smart", description: "Experience AI locker solutions tailored for modern needs. One WhatsApp message away from using a locker." },
    { imageSrc: HandleIconImage, title: "Pay-per-use", description: "Monetize locker usage through automated in-conversation payment links." },
    { imageSrc: ShopIconImage, title: "Management", description: "Special admin dialogue flows for WhatsApp automated locker management." }
  ];

  if (!cards) cards = defaultCards;

  const sectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (observer && observer.unobserve && sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <Container id="Different" ref={sectionRef} className={isVisible ? "slide-in" : ""}>
      <ThreeColumnContainer>
        {subheading && <Subheading as="h3">{subheading}</Subheading>}
        <Heading as="h2">{heading}</Heading>
        {description && <Description as="p">{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt={`${card.title} Icon`}/>
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Maximize efficiency with AI-powered, fully secure smart lockers that streamline your business operations."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};

export default Different;
